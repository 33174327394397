// btn
.btn {
	font-weight: 600;
	min-width: 190px;
	padding: 0.5rem 1.5rem;
	border: 0;
	border-radius: 3px;
	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
