// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark,
	&.bg-blank {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-detail-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// home intro
.home-intro-section {
	padding: 3vh 0 !important;
	@include media-breakpoint-down(lg) {
		padding: 3vh 0 0 0 !important;
	}
	.container {
		.one {
			max-width: 60% !important;
			flex-basis: 60% !important;
			display: flex;
			justify-content: center;
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
				flex-basis: 100% !important;
				padding: 0 2.5rem;
				padding-top: 9vh;
			}
			.title {
				color: #fff;
				font-family: $font-family-secondary;
				font-size: 64px;
				font-style: normal;
				font-weight: 400;
				line-height: 64px;
				@include media-breakpoint-down(lg) {
					font-size: 36px;
					line-height: 36px;
				}
			}
			.description {
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				max-width: 90%;
				i {
					color: #fff;

					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
				a {
					color: #c7ddd7 !important;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					text-decoration-line: underline;
					text-decoration-style: solid;
					text-decoration-skip-ink: none;
					text-decoration-thickness: auto;
					text-underline-offset: auto;
					text-underline-position: from-font;
				}
			}
		}
		.two {
			max-width: 40% !important;
			flex-basis: 40% !important;
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
				flex-basis: 100% !important;
				margin-bottom: 0;
				.column {
					padding: 0 !important;
				}
			}
		}
	}
}

// home-usp-section
.home-usp-section {
	padding: 4vh 0;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		width: 40%;
		height: 200%;
		pointer-events: none;
		background: url("/images/delasure/content-element-1-left.png");
		background-repeat: no-repeat;
		background-size: 100%;
		@media (max-width: 1100px) {
			top: 75%;
		}
		@media (max-width: 768px) {
			display: none;
		}
	}
	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 50%;
		width: 40%;
		height: 200%;
		pointer-events: none;
		background: url("/images/delasure/content-element-1-right.png");
		background-repeat: no-repeat;
		background-size: 100%;
		@media (max-width: 1100px) {
			top: 75%;
		}
		@media (max-width: 768px) {
			display: none;
		}
	}
	
	.container {
		@include media-breakpoint-down(lg) {
			padding: 0 4rem;
		}
		.column {
			@include media-breakpoint-down(lg) {
				padding: 1.5rem 0;
			}
			&.two {
				border-left: 1px solid $green-darker;
				border-right: 1px solid $green-darker;
				@include media-breakpoint-down(lg) {
					border-top: 1px solid $green-darker;
					border-bottom: 1px solid $green-darker;
					border-left: unset;
					border-right: unset;
				}
			}
			.title * {
				color: $black !important;
				font-family: $font-family-primary !important;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 0.8rem;
			}
			ul {
				list-style: none;
				padding: 0;
				display: flex;
				flex-flow: column;
				gap: 0.5rem;
				margin-bottom: 0;
				li {
					color: $black;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					display: flex;
					align-items: center;
					gap: 0.35rem;
					&::before {
						content: "\f138";
						color: $green;
						font-family: $font-awesome;
						font-size: 14px;
						font-style: normal;
						font-weight: 900;
						line-height: normal;
					}
				}
			}
		}
	}
}

.home-accommodations-section {
	.container {
		.grid {
			position: relative;
			z-index: 985;
			.item {
				max-width: 50% !important;
				flex-basis: 50% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.card {
					@extend .default-card;
				}
			}
		}
	}
	.container.price-availability {
		margin-top: 6vh;
		padding: 0 2.5rem;
		.title {
			color: $green-dark;
			font-family: $font-family-secondary;
			font-size: 48px;
			font-style: normal;
			font-weight: 400;
			line-height: 48px;
			@include media-breakpoint-down(lg) {
				font-size: 36px;
				line-height: 36px;
			}
		}
		.wysiwyg {
			color: $black;
			font-family: $font-family-primary;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			.btn {
				margin-top: 0.75rem;
			}
		}
		.two {
			@include media-breakpoint-down(lg) {
				padding-top: 3vh;
			}
			.wysiwyg {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 1rem;
				max-width: 70%;
				a {
					color: $green-dark;
					font-weight: 700;
					text-decoration-line: underline;
					text-decoration-style: solid;
					text-decoration-skip-ink: none;
					text-decoration-thickness: auto;
					text-underline-offset: auto;
				}
			}
		}
	}
}

// facilities section
.facilities-section {
	background: url("/images/delasure/content-element-2.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100%;
	@include media-breakpoint-down(lg) {
		background-position: top 50px center;
	}
	.intro {
		display: flex;
		flex-flow: column;
		justify-content: center;
		@include media-breakpoint-down(lg) {
			padding: 0 2.5rem !important;
		}
		.title {
			color: #fff;
			font-family: $font-family-secondary;
			font-size: 36px;
			font-style: normal;
			font-weight: 400;
			line-height: 36px;
		}
		.description {
			color: #fff;
			font-family: $font-family-primary;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			max-width: 75%;
		}
		.btn {
			margin-bottom: 0.5rem;
		}
	}
	.owl-carousel {
		min-height: unset;
		padding: 2vh 0;
		.owl-dots {
			display: none;
		}
		.owl-nav {
			position: absolute;
			max-width: 22%;
			bottom: calc(9vh - 22px);
			right: 6px;
			margin-left: auto;
			display: flex;
			justify-content: flex-end;
			button {
				transform: none;
				position: unset;
				width: unset;
				margin: 0 2px;
				i::before {
					content: "\f138";
					color: $blue;
					font-size: 20px;
				}
				&.owl-prev {
					i {
						transform: scaleX(-1);
					}
				}
			}
		}
		.owl-item {
			.card {
				border-radius: 3px;
				border: 0;
				.card-img-overlay {
					position: unset;
					background: $white;
					min-height: 9vh;
					max-height: 9vh;
					.card-title {
						position: absolute;
						top: 1.3rem;
						left: 1.3rem;
						color: #fff;
						font-family: $font-family-secondary;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px;
					}
					.card-description {
						color: $black;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						max-width: 75%;
						p {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

// home outro
.home-outro-section {
	.column {
		@include media-breakpoint-down(lg) {
			padding: 0 2.5rem !important;
		}
	}
	.wysiwyg {
		h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			color: $black;
			font-family: $font-family-primary;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		a {
			color: $blue;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-decoration-line: underline;
			text-decoration-style: solid;
		}
	}
}
// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
		.wysiwyg {
			max-width: 750px;
			margin: auto;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
