// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 40vh;
	min-height: 240px;
	max-height: 1080px;

	@include media-breakpoint-up(md) {
		height: 70vh;
	}
}

section.eyecatcher {
	position: relative;
	overflow: visible;
	&::after {
		content: "";
		position: absolute;
		left: -75px;
		bottom: -15%;
		width: 100%;
		height: 50vh;
		z-index: 900;
		pointer-events: none;
		background: url("/images/delasure/eyecatcher-element.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		@include media-breakpoint-down(lg) {
			left: 0;
			bottom: -35%;
			height: 30vh;
			background-size: unset;
			background-position: left -100px center;
		}
	}
	.owl-dots {
		display: none;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}
}
