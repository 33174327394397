// mini-sab
.mini-sab {
	position: relative;
	z-index: 995;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		padding: 10px 0;
		.container-holder {
			z-index: 100;
			position: relative;
			// below eyecatcher
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
		.w3-filter-collection {
			display: flex;
			justify-content: center;
			.filters {
				display: flex;
				flex-flow: row nowrap !important;
				max-width: 70% !important;
				width: 1140px !important;
				margin: auto;
				justify-content: center !important;
				.filter-element {
					flex: 0 0 auto !important;
					display: flex;
					align-items: center;
					&:has(.widget-btn) {
						flex: unset !important;
					}
					.w3-daterangepicker {
						.label {
							display: none !important;
						}
					}
					.input-holder {
						min-height: 60px !important;

						height: 100% !important;
						align-content: center;
						> div {
							height: 100% !important;
						}
						.label {
							display: none !important;
						}
					}
					.icon-input {
						height: 100%;
						display: flex;
						align-items: center;
					}
					input,
					.input {
						min-height: 55px !important;
						height: 100% !important;
					}
					button.widget-btn {
						all: unset;
						@extend .btn, .btn-primary;
						background: $green-dark;
						max-width: fit-content !important;
						color: $white;
						min-width: 138px !important;
						min-height: 60px;
						border-radius: 4px;
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-align: center;
						&::after,
						&::before {
							display: none;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
