// footer
.footer {
	background: $green-dark;
	padding: 6vh 0 1rem 0;
	.footer-main {
		.column {
			@include media-breakpoint-down(lg) {
				padding: 0 2.5rem !important;
			}
			color: $white;
			h6,
			h5,
			h4,
			h3,
			h2,
			h1 {
				color: $white;
				color: #fff;
				font-style: normal;
				font-weight: 400;
			}
			a {
				color: #c7ddd7;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-decoration-line: underline;
				text-decoration-style: solid;
				text-decoration-skip-ink: none;
				text-decoration-thickness: auto;
				text-underline-offset: auto;
				text-underline-position: from-font;
			}
			&.one {
				max-width: 60% !important;
				flex-basis: 60% !important;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@include media-breakpoint-down(md) {
					max-width: 100% !important;
					flex-basis: 100% !important;
					margin-bottom: 6vh;
					* {
						text-align: center;
					}
				}
				.vocabulary-term-list {
					display: flex;
					gap: 0.8rem;
					@include media-breakpoint-down(md) {
						width: 100%;
						justify-content: center;
					}
					li,
					a {
						font-size: 24px !important;
						.list-item-title {
							display: none;
							text-decoration: none;
							i::before {
								color: #aec6bc;
								text-align: center;

								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}
						}
						&:hover {
							i {
								transform: rotate(360deg);
								transition: 0.5s ease;

								&::before {
									color: $green-light;
								}
							}
						}
					}
				}
			}
			&.two,
			&.three {
				max-width: 20% !important;
				flex-basis: 20% !important;
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@include media-breakpoint-down(md) {
					max-width: 100% !important;
					flex-basis: 100% !important;
					margin: 3vh auto;
				}
				img {
					margin-bottom: 1.1rem;
				}
			}
		}
	}
	.footer-copyright {
		padding-bottom: 1.2rem;
		margin-top: 12vh;
		.list {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 1rem;
			a {
				color: #c7ddd7;
				text-align: center;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
