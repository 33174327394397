// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $green;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			width: 135px;
			transition: 0.35s ease;
		}
	}
	// floating action bar
	div.floating-action-bar {
		position: fixed;
		z-index: 999;
		top: 0;
		right: 0;
		height: 100%;
		width: 80px;
		max-width: 80px;
		background: $grey;
		overflow: hidden;
		@include media-breakpoint-down(md) {
			top: unset;
			bottom: 0;
			height: 10vh;
			max-height: 10vh;
			width: 100%;
			max-width: unset;
		}
		ul {
			height: 100%;
			width: 100%;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			gap: 1.5rem;
			@include media-breakpoint-down(md) {
				gap: 0.3rem;
				flex-flow: row;
				justify-content: flex-end;
				padding-right: 1.3rem;
			}
			li,
			a {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 10px;
				color: $green-darker;
				gap: 7px;
				text-decoration: none;
				i {
					width: 40px;
					aspect-ratio: 1/1;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: $white;
					&::before {
						font-size: 14px;
						color: $green-darker;
						font-weight: 900;
					}
					.list-item-title {
						font-size: 10px;
						font-weight: 400;
						line-height: 12.1px;
						text-align: center;
						color: $green-darker;
					}
				}
				&:hover {
					i {
						transform: rotate(360deg) scaleX(-1);
						transition: 0.5s ease;
						&::before {
							color: $blue;
							transform: scaleX(-1);
						}
					}
					.list-item-title {
						font-weight: 900;
						color: $blue;
					}
				}
			}
		}
	}
	.container,
	.container-fluid {
		.container-holder {
			> .column {
				display: flex;
				align-items: center;
				padding-right: calc(80px + 2.5rem);
				padding-left: 2.5rem;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding: 0 1rem;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			.column {
				justify-content: flex-end;
				div.vocabulary-term-list {
					height: 100%;
					@include media-breakpoint-down(lg) {
						margin-left: auto;
					}
					ul.vocabulary-term-list {
						height: 100%;
						display: flex;
						align-items: center;
						padding: 8px 0;

						li,
						a {
							display: flex;
							flex-flow: row-reverse;
							gap: 1rem;
							text-decoration: none;
							.list-item-title {
								max-width: 150px;
								color: #fff;
								text-align: right;
								font-size: 11px;
								font-style: normal;
								font-weight: 500;
								line-height: 12px;
								@include media-breakpoint-down(md) {
									font-size: 8px;
								}
							}
							img{
								@include media-breakpoint-down(md){
									max-width: 110px;
								}
							}
						}
					}
				}
			}
		}
		&.bottom {
			background: $white;
			&:has(.navbar-toggler[aria-expanded="true"]) {
				@include media-breakpoint-down(lg) {
					background: linear-gradient(to bottom, $white, $grey 90%);
				}
			}
			.container-holder {
				height: 100%;
			}
			.column {
				height: 100%;
				justify-content: center;
				align-items: center;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		position: absolute;
		left: 15px;
		top: 15px;
		.navbar-toggler {
			color: $black !important;
			background: $grey;
			border-radius: 50%;
			width: 54px;
			aspect-ratio: 1/1;
			i {
				transition: 1s ease;
				&::before {
					color: $black !important;
					font-size: 20px;
				}
			}
			&[aria-expanded="true"] {
				i {
					transform: scaleX(-1) rotate(360deg);
					transition: 1s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 218px;
		margin: 0.95rem 3rem 0.95rem 0;
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 200px;
			margin: 15px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 1;

			.navbar-nav {
				padding: 1.5rem 15px;

				.nav-item {
					width: 100%;

					.nav-link {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						color: $green-dark;
						font-weight: 600;
						font-size: 16px;
					}
				}
			}

			.dropdown-menu.show {
				transform: none !important;
				background: none;
				border: none;
				position: static !important;

				.nav-link {
					font-weight: 200;
					padding-left: 0.5rem;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			max-width: fit-content;
			margin-right: auto;
			.navbar-nav {
				display: flex;
				gap: 1.25rem;
				.nav-item {
					.nav-link {
						color: $green-darker;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: underline $avacado-green;
							text-underline-offset: 10px;
						}
					}
					.dropdown-menu.show {
						border: 0;
						box-shadow: none;
						background: $white;
						padding: 1.5rem;
						text-align: center;
						&::after,
						&::before {
							display: none;
						}
						.nav-link {
							text-decoration: none;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
	// search-book-button
	div.search-book-button {
		position: absolute;
		right: 20px;
		bottom: 0;
		max-width: fit-content;
		transform: translateY(50%);
		z-index: 1000;
		@include media-breakpoint-down(md) {
			position: fixed;
			bottom: 10vh;
			right: 0px;
			transform: none;
		}
		a {
			width: 134px;
			aspect-ratio: 1/1;
			border-radius: 50%;
			background: $blue;
			color: $white;
			padding: 10px;
			display: flex;
			flex-flow: column-reverse;
			gap: 3px;
			color: $white;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			box-shadow: 0px 0px 8px #585858;
			text-decoration: none;
			transition: 0.35s ease;
			@include media-breakpoint-down(md) {
				border-radius: 6px 6px 0 0;
				flex-flow: row;
				font-size: 13px;
				aspect-ratio: unset;
				box-shadow: none;
				align-items: center;
			}
			i {
				font-size: 36px;
				font-weight: 900;
				@include media-breakpoint-down(md) {
					font-size: 18px;
				}
			}
			&:hover {
				transform: rotate(10deg);
				box-shadow: none;
				transition: 0.35s ease;
			}
		}
	}
	// site-switcher
	.site-switcher {
		margin-left: 1.5rem;

		.nav-link {
			display: flex !important;
			flex-flow: row nowrap !important;
			align-items: center;
			border: 0;
			&:hover {
				border: 0 !important;
			}
			&::after {
				color: $white;
			}
		}

		.dropdown-menu.show {
			background-color: $body-bg !important;
			border: 0 !important;
			box-shadow: none !important;
		}

		@include media-breakpoint-down(lg) {
			margin-left: 0.5rem;
			// z-index: 1000;
		}
	}
}
